import { useEffect, useRef, useState } from 'react';

function useCounter(number: number, duration: number) {
  const [count, setCount] = useState(0);
  const timerRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    let start = 0;
    const end = number;
    // if zero, return
    if (start === end) return;

    // find duration per increment
    let totalMilSecDur = duration;
    let incrementTime = totalMilSecDur / end;

    // timer increments start counter
    // then updates count
    // ends if start reaches end
    timerRef.current = setInterval(() => {
      start += 1;
      setCount(start);
      if (start === end && timerRef.current) {
        clearInterval(timerRef.current);
      }
    }, incrementTime);

    // dependency array

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [number, duration]);

  return count;
}

export default useCounter;
