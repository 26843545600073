'use client';

import Container from '#/components/Container';
import styled from 'styled-components';
import { Button, HeaderText, JumboText, MEDIA_QUERY_MOBILE } from 'bouqei';
import { Flex } from 'bouqei';
import colors, { darkerPrimary } from '#/lib/colors';
import Counter from './animations/Counter';
import { montSuperJumbo } from '#/components/StyleBag';
import { rgba } from 'polished';
import Link from 'next/link';
import { useBadlandsAuth } from '#/context/AuthProvider';

type Props = {
  reportsTotal: number;
  nflReportsTotal: number;
  isLoggedIn?: boolean;
};

const Stats: React.FC<Props> = ({ reportsTotal, nflReportsTotal }) => {
  const { isLoggedIn } = useBadlandsAuth();
  return (
    <Content flexDirection="column" gap="32px">
      <Flex
        className="title"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="16px"
      >
        <JumboText format="light">By The Numbers</JumboText>
        <HeaderText
          type={3}
          style={{ textAlign: 'center' }}
          weight={700}
          format="light"
        >
          Badlands 2023 #OFFSZN
        </HeaderText>
      </Flex>
      <Container className="grid" $contained>
        <Flex className="numbers">
          <Column>
            <JumboText format="light">
              <Counter number={reportsTotal} />
            </JumboText>
            <HeaderText type={4} format="light">
              College Scouting Reports
            </HeaderText>
          </Column>
          <Column>
            <JumboText format="light">
              <Counter number={nflReportsTotal} />
            </JumboText>
            <HeaderText type={4} format="light">
              Pro Player
              <br />
              Reports
            </HeaderText>
          </Column>
          <Column>
            <JumboText format="light">
              <Counter number={3000} />+
            </JumboText>
            <HeaderText type={4} format="light">
              Subscriptions
            </HeaderText>
          </Column>
        </Flex>
        {isLoggedIn ? null : (
          <Upsell flexDirection="column" gap="32px">
            <HeaderText type={2} format="light">
              Join the team! Become a Badlands Patron, today.
            </HeaderText>
            <Link
              href="https://www.patreon.com/BadlandsTOJ/membership"
              target="_blank"
            >
              <Button format="light">Learn More</Button>
            </Link>
          </Upsell>
        )}
      </Container>
    </Content>
  );
};

export default Stats;

const Content = styled(Flex)`
  background-image: linear-gradient(
    to top,
    ${rgba(colors.primary, 0.25)},
    ${rgba(darkerPrimary, 1)} 80%
  );
  background-color: ${colors.primary};
  padding: 64px 0;

  .title {
    h1 {
      ${montSuperJumbo};
      font-size: 5rem;
    }
  }

  .numbers {
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 64px;
  }

  @media ${MEDIA_QUERY_MOBILE} {
    .numbers {
      flex-direction: column;
    }
    .title {
      h1 {
        font-size: 4.25rem;
        line-height: 4.5rem;
        text-align: center;
      }
    }
  }
`;

const Column = styled.div`
  width: 300px;
  h1 {
    font-size: 5.75rem;
  }
  text-align: center;
`;

const Upsell = styled(Flex)`
  border-top: solid 1px rgba(255, 255, 255, 0.25);
  padding: 32px 0;
  margin-top: 32px;
  justify-content: center;
  align-items: center;
  button {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    color: ${colors.primary};
    background-color: ${colors.white};
    min-width: 250px;
  }
`;
