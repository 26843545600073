'use client';

import { TypeInternalLink } from '#/lib/types';
// import { Flex } from 'bouqei';
import {
  HeaderText,
  JumboText,
  // MEDIA_QUERY_LARGE,
  MEDIA_QUERY_MOBILE,
  // MEDIA_QUERY_XL,
  // MEDIA_QUERY_XXL,
  columnStyles,
} from 'bouqei';
import styled from 'styled-components';
import colors, { darkPrimary, darkerPrimary } from '#/lib/colors';
import { rgba } from 'polished';
// import dayjs from 'dayjs';
import Image from 'next/image';
import { superJumbo } from '#/components/StyleBag';
import Link from 'next/link';

type Props = {
  links: TypeInternalLink[];
};

// const toLinkHref = (data: TypeInternalLink) => {
//   if (data.fields.board) {
//     return `/boards/${data.fields.board.fields.slug}`;
//   }

//   if (data.fields.article) {
//     return `/posts/${data.fields.article.fields.slug}`;
//   }

//   return data.fields.directLink || '/';
// };

// const hasReachedDate = (releaseDate?: string) => {
//   const isSame = releaseDate ? dayjs().isSame(releaseDate) : true;
//   const isAfter = releaseDate ? dayjs().isAfter(releaseDate) : true;
//   const reachedDate = isSame || isAfter;
//   return reachedDate;
// };

const LinkColumns: React.FC<Props> = () => {
  return (
    <Wrapper>
      <TitleSection id="title-section">
        <div className="eyebrow">
          <HeaderText type={4} format="light">
            Badlands Presents:
          </HeaderText>
        </div>
        <JumboText format="light">
          The Off<span>SZN</span>
        </JumboText>
        <LogoContainer>
          <HeaderText type={6} format="light">
            Brought to you by:
          </HeaderText>
          <Link href="https://prophetbettingexchange.com/" target="_blank">
            <Image
              alt="Prophet Exchange"
              width={1118}
              height={284}
              src="/images/PE_Green.png"
            />
          </Link>
        </LogoContainer>
        {/* <ScrollDown className="scroll-down" selector="#link-list" /> */}
      </TitleSection>
      {/* <Container id="link-list" $contained>
        <Grid>
          {links.map((link, index) => {
            const reachedDate = hasReachedDate(link.fields.releaseDate);
            const { media, description, text, board, releaseDate } =
              link.fields;
            return (
              <Column
                key={link.sys.id}
                className={`box-${index + 1}`}
                image={media ? toAssetUrl(media) : '/images/chalk_green.png'}
              >
                <Link
                  className={reachedDate ? '' : 'disabled'}
                  href={reachedDate ? toLinkHref(link) : '#'}
                >
                  <Overlay
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Flex
                      className="inner-text"
                      flexDirection="column"
                      gap="16px"
                    >
                      <HeaderText
                        type={1}
                        className="montserrat"
                        format="light"
                        weight={900}
                      >
                        {text}
                      </HeaderText>
                      <HeaderText type={6} format="light">
                        {description}
                      </HeaderText>
                      {board && reachedDate && (
                        <Paragraph className="last-updated" format="light">
                          Updated{' '}
                          <RelativeDateTime date={board.sys.updatedAt} />
                        </Paragraph>
                      )}
                      {!reachedDate && (
                        <Paragraph format="light">
                          <em>
                            Coming {dayjs(releaseDate).format('MMMM YYYY')}
                          </em>
                        </Paragraph>
                      )}
                      {board && board.fields.tiers && (
                        <TierList gap="8px">
                          {board.fields.tiers.map(tier => (
                            <Paragraph
                              weight={400}
                              key={`id-${tier}`}
                              format="light"
                            >
                              <i className="fas fa-star" />
                              {tier}
                            </Paragraph>
                          ))}
                        </TierList>
                      )}
                    </Flex>
                  </Overlay>
                </Link>
              </Column>
            );
          })}
        </Grid>
      </Container> */}
    </Wrapper>
  );
};

export default LinkColumns;

const Wrapper = styled.div`
  background-color: ${darkPrimary};
  position: relative;
  background: ${colors.light};
`;

// const Grid = styled.div`
//   padding: 48px 0 48px;
//   display: grid;
//   grid-template-columns: repeat(6, 1fr);
//   grid-auto-rows: 45vh;
//   gap: 8px;

//   .box-4,
//   .box-5 {
//     grid-column: span 3;
//   }

//   @media ${MEDIA_QUERY_XXL} {
//     padding: 48px 2.5%;
//   }

//   @media ${MEDIA_QUERY_LARGE} {
//     grid-template-columns: repeat(4, 1fr);
//     grid-auto-rows: 35vh;
//     .box-4 {
//       grid-column: span 2;
//     }
//     .box-5 {
//       grid-column: span 4;
//     }
//   }

//   @media ${MEDIA_QUERY_MOBILE} {
//     grid-template-columns: repeat(1, 1fr);
//     grid-auto-rows: 40vh;
//     .box-4,
//     .box-5 {
//       grid-column: span 2;
//     }
//   }
// `;

// const Column = styled.div<{ image: string }>`
//   background-size: cover;
//   background-image: linear-gradient(
//       180deg,
//       rgba(0, 0, 0, 0.25) 0%,
//       rgba(0, 0, 0, 0.35) 30%,
//       rgba(0, 0, 0, 0.5) 100%
//     ),
//     url(${({ image }) => image});
//   position: relative;
//   grid-column: span 2;
//   border-radius: 8px;
//   box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;

//   h5,
//   h6,
//   p {
//     color: ${colors.white};
//   }

//   a.disabled {
//     pointer-events: none;
//   }
// `;

// const Overlay = styled(Flex)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   padding: 0 10%;
//   background-color: rgba(0, 0, 0, 0);
//   transition: background-color 0.25s ease-in-out;

//   .inner-text {
//     transform: scale(0.95);
//     transition: transform 0.5s ease-in-out;
//   }

//   h1 {
//     font-style: italic;
//     text-transform: uppercase;
//   }

//   &:hover {
//     background-color: rgba(0, 0, 0, 0.15);
//     .inner-text {
//       transform: scale(1);
//     }
//   }

//   @media ${MEDIA_QUERY_XL} {
//     padding: 0 5%;
//   }
// `;

// const TierList = styled(Flex)`
//   p i {
//     margin-right: 8px;
//   }
// `;

const TitleSection = styled.div`
  ${columnStyles('center', 'center')}
  background-image: linear-gradient(
    to bottom,
    ${rgba(colors.primary, 0.25)},
    ${rgba(darkerPrimary, 1)} 80%
  );
  background-color: ${colors.primary};
  flex-direction: column;
  position: relative;
  gap: 16px;
  text-align: center;
  padding: 112px 5% 64px;
  color: #f5f5f5;

  div {
    color: #f5f5f5;
  }

  h1 {
    color: #ffffff;
    text-align: left;
    ${superJumbo};
    text-transform: uppercase;
    font-style: italic;
    font-size: 6.5rem;
    font-weight: 800;
    line-height: 7rem;
    transform: scale(1, 1.1);
    font-style: italic;
    text-transform: uppercase;
    span {
      color: #00cc99;
      font: inherit;
      line-height: inherit;
    }
  }

  .presents {
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
  }

  /* .scroll-down {
    display: none;
  } */

  @media ${MEDIA_QUERY_MOBILE} {
    height: auto;
    padding: 96px 5% 64px;
    .scroll-down {
      display: block;
    }
    h1 {
      font-size: 5rem;
      line-height: 5.5rem;
      text-align: center;
    }
    h4 {
      font-size: 1.6rem;
    }
  }
`;

const LogoContainer = styled.div`
  position: relative;
  max-width: 250px;
  text-align: center;

  img {
    width: 100%;
    height: auto;
  }

  h6 {
    font-weight: 500;
    margin-bottom: 8px;
  }

  @media ${MEDIA_QUERY_MOBILE} {
    max-width: 200px;
  }
`;
