import styled, { css } from 'styled-components';
import colors from '../lib/colors';
import Container from './Container';
import { lighten } from 'polished';

export const jumboStyle = css`
  font-size: 5rem;
  text-transform: uppercase;
`;

export const superJumbo = css`
  font-size: 6.5rem;
  line-height: 7rem;
  transform: scale(1, 1.15);
`;

export const overlayShadowHeavy = css`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.45) 15%,
    rgba(0, 0, 0, 0.25) 50%,
    rgba(0, 0, 0, 0.55) 75%,
    rgba(0, 0, 0, 0.9) 100%
  );
`;

export const medPara = css`
  font-size: 1.25rem;
  line-height: 1.5em;
`;

export const bigPara = css`
  font-size: 1.5rem;
  line-height: 1.75em;
`;

export const tallText = css`
  transform: scale(1, 1.1);
`;

export const TextContainer = styled(Container)`
  padding: 0 5%;
`;

export const badlandsOutline = css`
  color: ${colors.white};
  text-shadow: -1px 1px 0 ${colors.primary}, 1px 1px 0 ${colors.primary},
    1px -1px 0 ${colors.primary}, -1px -1px 0 ${colors.primary};
`;

export const cardShadow = css`
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
`;

export const darkCardShadow = css`
  box-shadow: rgba(0, 0, 0, 0.6) 0 2px 6px;
`;

export const montSuperJumbo = css`
  font-size: 6.5rem;
  font-weight: 800;
  line-height: 7rem;
  text-transform: uppercase;
`;

export const toTextStroke = (color: string, size: string = '1px') => css`
  text-shadow: -${size} -${size} 0 ${color}, ${size} -${size} 0 ${color},
    -${size} ${size} 0 ${color}, ${size} ${size} 0 ${color};
`;

export const markdownStyles = css`
  .markdown {
    padding: 16px 0;

    h5 {
      color: rgba(0, 0, 0, 0.65);
      font-weight: 500;
      margin: 0 0 8px;
    }

    h6 {
      margin-bottom: 8px;
    }

    p {
      text-align: justify;
      text-justify: inter-word;
      color: ${lighten(0.4, colors.black)};
      line-height: 1.65rem;
    }
    p + p {
      margin-top: 8px;
    }

    p + h5 {
      margin-top: 16px;
    }
    p + h6 {
      margin-top: 16px;
    }
  }
`;
