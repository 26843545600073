import useCounter from '#/hooks/useCounter';
import { Fragment } from 'react';

type Props = {
  number: number;
  duration?: number;
};

const Counter: React.FC<Props> = ({ number, duration = 5000 }) => {
  const count = useCounter(number, duration);
  return <Fragment>{count}</Fragment>;
};

export default Counter;
